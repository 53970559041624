@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:wght@100..900&display=swap');

:root {
  --brand-color-1: #1355FF;
  --brand-color-2: #160042;
  --brand-color-3: #6B7094;
  --brand-color-4: #b83bfb;

  /*Text colors*/
  --primary-text: #07090D;
  --secondary-text: #818A9C;
  --third-text: #8195D5;

  --primary-white-text: #FFFFFF;

  --border-color: #E7EAEE;

  /*Input colors*/
  --input-border-color: #E7EAEE;
  --input-placeholder-color: #ACB4C3;

  /*Dashboard*/
  --dashboard-background: #F7F8FE;

  /*Toastify*/
  
  --toastify-color-success: #1355FF !important; /* Background color for success toast */
  --toastify-icon-color-success: var(--brand-color-1) !important; /* Icon color for success toast */
  --toastify-text-color-success: var(--brand-color-1) !important;
  --toastify-color-progress-success: #8195D5 !important;
  --toastify-text-color-light: var(--brand-color-1) !important;
  --toastify-text-color-dark: var(--brand-color-1) !important;
  --toastify-toast-min-height: 84px !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  color: var(--primary-text);
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* ADYEN CHECKOUT STYLING */
#dropin-container {
  margin-top: 20px !important;
  max-width: 700px;
  margin: 0 auto;
}

.adyen-checkout__payment-method--selected {
  background: none !important;
  border: none !important;
}

.adyen-checkout__button--pay {
  background-color: var(--brand-color-1) !important;
  font-weight: 900;
  border-radius: 0;
}

.adyen-checkout__button__text {
  display: none !important;
}

.adyen-checkout__button__content::after {
  color: #fff;
  display: block;
  content: "Start 14 Day FREE Trial";
}

.adyen-checkout__input {
  background:  var(--dashboard-background) !important;
  border: solid 1px var(--dashboard-background) !important;
  outline: none !important;
  font-size: 14px !important; 
}

.adyen-checkout__input::placeholder {
      color: #8195D5 !important;
}

.adyen-checkout__dropdown__button {
  background:  var(--dashboard-background) !important;
  border: solid 1px var(--dashboard-background) !important;
  outline: none !important;
  font-size: 14px !important; 
}

.Select-module_adyen-checkout__filter-input__CwPBS {
    background:  var(--dashboard-background) !important;
}

.react-responsive-modal-modal {
  border-radius: 6px !important;
}

/* TOAST */

.Toastify__toast {
  width: 348px !important;
  /* padding: 0 !important; */
  font-weight: 500 !important;
  letter-spacing: -0.019999999552965164px !important;
}

.Toastify__toast-icon {
  flex-shrink: 0 !important; 
  display: inline-block !important;
}

.Toastify__toast-body {
  gap: 4px !important;
}

.Toastify__toast-container--top-right {
  right: 3em !important;
}

.Toastify__toast--warning .Toastify__close-button {
  margin-top: 2px !important;  /* Adjust the value for top margin as needed */
  margin-right: 2px !important; /* Adjust the value for right margin as needed */
}

/* Targeting the close button within error toasts */
.Toastify__toast--error .Toastify__close-button {
  margin-top: 2px !important;  /* Adjust the value for top margin as needed */
  margin-right: 2px !important; /* Adjust the value for right margin as needed */
}